import React from 'react';

import '../styles/experience.scss';
import TabsSection from "./subComponents/TabsComponent";

const Experience = () => {
    const tabs = [
        {
            label: 'Trionika',
            title: 'Front-end developer',
            date: 'Jul 2021 - Present',
            list: [
                'Developed and maintained high-traffic websites (100k+ pages), achieving a 90% improvement in pageloading speed through the implementation of an infinite scroll system.',
                'Integrated and enhanced the partner dashboard, contributing to a 17% increase in affiliate programprofits.',
                'I collaborated with cross-functional teams using Agile and Scrum methodologies to develop newcomponents and optimize existing ones.',
                'Successfully launched marketing websites under tight deadlines, helping the company navigate acrisis and increase revenue by 35%.',
                'Implemented unit testing to ensure the reliability of key features and maintain high-quality codestandards.',
                'I worked with modern technologies, including React, TypeScript, and data visualization tools likeChart.js.'
            ]
        },
        {
            label: 'Dadvi',
            title: 'Front-end developer',
            date: 'Aug 2020 - Jul 2021',
            list: [
                'Developed SEO-optimized landing pages and e-commerce websites, improving client conversion rates.',
                'Created and integrated websites with Bitrix 1C, enhancing e-commerce functionality.',
                'Collaborated with the marketing team to optimize and refine client websites, leading to increased site traffic and engagement.',
            ]
        },
        {
            label: 'Dadvi(SEO)',
            title: 'SEO specialist (team lead)',
            date: 'Jan 2016 - Aug 2020',
            list: [
                'Developed and implemented SEO strategies for clients, achieving top rankings in highly competitiveniches (e.g., "luxury real estate," "DDoS protection").',
                'Improved on-page and technical SEO, resulting in significant increases in organic traffic and visibility.'
            ]
        },
    ];

    return (
        <div className='container section_experience section_detect' id={'experience'}>
            <div className={'home_section'}>
                <h2 className={'section_title'}>
                    2. Experience
                </h2>
                <TabsSection tabs={tabs}/>
            </div>
        </div>
    );
};

export default Experience;